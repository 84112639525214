import { useEffect } from 'react';

import { GetStaticPropsContext } from 'next';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { selectCurrentCompany, selectCurrentUser } from '@/features/auth/authSlice';

import PrimaryLayout from '../components/layouts/primary/PrimaryLayout';

import { NextPageWithLayout } from './page';

const Home: NextPageWithLayout = () => {
  const { locale, push } = useRouter();

  const currentCompany = useSelector(selectCurrentCompany);
  const currentUser = useSelector(selectCurrentUser);
  useEffect(() => {
    if (currentUser) {
      if (currentCompany?.isRequester) {
        push('/assessment-requests', '/assessment-requests', { locale: locale || 'en' });
      } else {
        push('/assessments', '/assessments', { locale: locale || 'en' });
      }
    }
  }, [currentUser, currentCompany?.isRequester, locale, push]);

  return null;
};

export default Home;

Home.getLayout = (page) => {
  return <PrimaryLayout>{page}</PrimaryLayout>;
};

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: (await import(`../../messages/${locale}.json`)).default,
    },
  };
}
